export const baseurl =
  "https://nirmanbackend.com";
let admin = `${baseurl}/api/v1`;
let property = `${baseurl}/api/v1`;
const Apiconfigs = {
  login: `${admin}/admin/login`,
  getProfile: `${admin}/admin/getProfile`,
  uploadFile: `${property}/property/uploadFile`,
  addProperty: `${property}/property/addProperty`,
  listProperty: `${property}/property/listProperty`,
  deleteProperty: `${property}/property/deleteProperty`,
};
export default Apiconfigs;
import React from "react";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExplorePropety from "../pages/banner/ExplorePropety";
import WalkthrughVideo from "../pages/banner/WalkthrughVideo";
import Achivement from "../pages/banner/Achivement";
import AmentiesOffer from "../pages/banner/AmentiesOffer";
import CustomerTestomonial from "../pages/banner/CustomerTestomonial";
import WhyChooseUs from "../pages/banner/WhyChooseUs";
import BlogSection from "../pages/banner/BlogSection";
import ContactUs from "../pages/banner/ContactUs";
import TabButton from "../pages/banner/TabButton";
import Hero from "./Hero";
const useStyles = makeStyles((theme) => ({}));

export default function Projects() {
  return (
    <Box mt={8}>
      <Hero
        cName="hero"
        heroImage="https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2FAbout%20Cover%20Image.jpg?alt=media&token=4b80a250-15fa-4f44-a49a-51bb741cd5a1"
        title="Our Properties"
        description="2 BHK, 3 BHK ,4 BHK Loan and Registry Flats in Chattarpur, South Delhi"
        buttontext="Book your Free Site Visit Now"
        url="tel:9899550700"
        btnClass="show"
      />
      <Box id="project">
        <Container>
          <TabButton />
        </Container>
      </Box>
      <ExplorePropety />

      <WalkthrughVideo />
      <Achivement />
      <AmentiesOffer />
      <CustomerTestomonial />
      <WhyChooseUs />
      <BlogSection />
      <ContactUs />
    </Box>
  );
}

import {
  Box,
  Container,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import CustomButton from "../../pages/banner/CustomButton";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Apiconfigs from "../../ApiConfig/ApiConfig";
import axios from "axios";
import { TakanValue } from "../../utility/index";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    background: "#FFF5DB",
    strokeWidth: "1px",
    border: "1px solid #FFC83A",
    padding: "30px",
    borderRadius: "5px",
    marginBottom: "25px",
  },
}));
const styles = {
  borderRadius: "5px",
  background: "#FFC83A",
  // This will change the placeholder color for all TextField components
  "& ::placeholder": {
    color: "#535353 !important",

    fontFamily: "Poppins",
    fontSize: "12px !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    lineHeight: "normal",
    textTransform: "capitalize", // Change this to the desired color
  },
};
export default function ContactUs() {
  const classes = useStyles();

  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const postUserData = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };
  const submitData = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.login,
        method: "POST",
        data: {
          email: userData?.email,
          password: userData?.password,
        },
      });
      if (res) {
        toast.success("Login successfully");
        window.sessionStorage.setItem("token", res?.data?.result?.token);
        await TakanValue(res?.data?.result?.token);

        navigate("/admin-list-blogs");
      }
    } catch (error) {
      toast.error("Invalid Login Credential.");

      console.log(error);
    }
  };
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Container>
        <Box mt={5} maxWidth={500}>
          <img
            src="\images\Full-Logo-tagline-496x295-removebg-preview.png"
            width={"100%"}
          />
        </Box>
        <Box
          width="100%"
          className={classes.formContainer}
          mt={5}
          maxWidth={500}
        >
          <Box mb={2}>
            <Typography
              style={{
                color: "#000",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
            >
              Admin Login
            </Typography>
          </Box>

          <Box sx={{ marginBottom: "16px" }}>
            <TextField
              sx={styles}
              fullWidth
              variant="outlined"
              placeholder="Email Id "
              value={userData.email}
              name="email"
              onChange={postUserData}
            />
            <FormHelperText error>
              {isSubmit &&
                userData.email === "" &&
                "Please enter your email id"}
            </FormHelperText>
          </Box>
          <Box sx={{ marginBottom: "16px" }}>
            <TextField
              sx={styles}
              fullWidth
              variant="outlined"
              placeholder="Password"
              name="password"
              value={userData.password}
              onChange={postUserData}
            />
            <FormHelperText error>
              {isSubmit &&
                userData.password === "" &&
                "Please enter your mobile number"}
            </FormHelperText>
          </Box>

          <CustomButton submitData={submitData} btnName="Submit" type="form" />
        </Box>
      </Container>
    </Box>
  );
}

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useState, useEffect } from "react";
import SliderCard from "./SliderCard";
import Heading from "../../component/Heading";
import { projectList } from "../../data";
const useStyles = makeStyles((theme) => ({
  tabSection: {
    width: "100%",
    padding: "18px 0px 0px",
    "@media(max-width:767)": {
      background: "#FFEFC7",
    },
  },
  nonactivebtn: {
    paddingBottom: "16px !important",
    color: "#000 !important",
    fontSize: "24px !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    lineHeight: "normal !important",
    "@media(max-width:767px)": {
      fontSize: "12px !important",
      color: "#000",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: "600 !important",
      lineHeight: "normal !important",
      textTransform: "capitalize !important",
    },
  },
  activeBtn: {
    position: "relative",
    borderRadius: "inherit !important",
    paddingBottom: "16px !important",
    color: "#000 !important",
    fontSize: "24px !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    lineHeight: "normal !important",
    "@media(max-width:767px)": {
      fontSize: "12px !important",
      color: "#000",
      fontFamily: "Poppins !important",
      fontStyle: "normal !important",
      fontWeight: "600 !important",
      lineHeight: "normal !important",
      textTransform: "capitalize !important",
    },
    "& ::after": {
      content: " '' ",
      position: "absolute",
      width: "190px",
      height: "5px",
      background: "#FFC83A",
      bottom: "0px",
      left: "0px",
      "@media(max-width:767px)": {
        height: "2px",
      },
    },
  },
  headingBOX: {
    "@media(max-width:767px)": {
      paddingBottom: "32px",
    },
  },
}));
export default function TabButton() {
  const classes = useStyles();
  const [active, setActive] = useState("all");
  const [allData, setAllData] = useState(projectList);

  useEffect(() => {
    if (active) {
      const filterData =
        projectList &&
        projectList.filter((item, i) => item.category === active);

      setAllData(filterData);
    }
  }, [active]);

  return (
    <Paper
      className={classes.mainTabSection}
      style={{ padding: "25px 0px", boxShadow: "none" }}
    >
      <Box className={classes.headingBOX} style={{ paddingLeft: "14px" }}>
        <Heading title="Our Properties" />
      </Box>
      <Grid container>
        <Box className={classes.tabSection}>
          <Button
            onClick={() => setActive("all")}
            className={
              active === "all" ? classes.activeBtn : classes.nonactivebtn
            }
          >
            All Projects
          </Button>
          <Button
            onClick={() => setActive("Trending")}
            className={
              active === "Trending" ? classes.activeBtn : classes.nonactivebtn
            }
          >
            Trending Projects
          </Button>
          <Button
            onClick={() => setActive("BHK2")}
            className={
              active === "BHK2" ? classes.activeBtn : classes.nonactivebtn
            }
          >
            2 BHK
          </Button>
          <Button
            onClick={() => setActive("BHK3")}
            className={
              active === "BHK3" ? classes.activeBtn : classes.nonactivebtn
            }
          >
            3 BHK
          </Button>
          <Button
            onClick={() => setActive("BHK4")}
            className={
              active === "BHK4" ? classes.activeBtn : classes.nonactivebtn
            }
          >
            4 BHK
          </Button>
        </Box>
      </Grid>
      <SliderCard allData={allData.length > 0 ? allData : projectList} />
    </Paper>
  );
}

import React, { useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Phone } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import Heading from "../../src/component/Heading";
import { Button } from "react-bootstrap";
import ApartMentCard from "../../src/component/ApartMentCard";
import AcoordianComponent from "../components/AcoordianComponent";
import Contact from "../pages/banner/ContactUs";

import {
  locationMandi,
  locationghitorni,
  loctionssChattarpur,
  locationGurgaon,
  locationSouthdelhi,
} from "../../src/data";
import ExplorePropety from "../pages/banner/ExplorePropety";
import Achivement from "../pages/banner/Achivement";
import DialogComponent from "../components/DialogComponent";
const useStyles = makeStyles((theme) => ({
  contentBg: {
    background: "#FFD260",
    padding: "28px 31px",
    marginTop: "34px",
    "& h6": {
      color: "#000",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    "& h5": {
      color: "#000",
      textAlign: "center",
      fontFamily: "'Poppins',serif !important",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    // "& button": {
    //   color: "#FFD260",
    //   fontFamily: "Poppins",
    //   background: "#000",
    //   fontSize: "12px",
    //   width: "100%",
    //   fontStyle: "normal",
    //   fontWeight: "600",
    //   lineHeight: "normal",
    //   letterSpacing: "2.4px",
    //   textTransform: "uppercase",
    //   padding: "15px 0px",
    // },
  },
  mainClass: {
    background: "#fff",
    marginTop: "60px",
    "& .imgBox": {
      width: "100%",
    },
  },
  content: {
    padding: "0px 5px",
    "& h6": {
      color: "#000",
      paddingTop: "23px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    "& p": {
      color: "#777",
      padding: "10px 0px 8px",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    "& h5": {
      color: "#383838",

      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
  },
  riben: {
    color: "#FFF !important",
    fontFamily: "Poppins",
    fontSize: "12px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important ",
    lineHeight: "normal !important",
    textTransform: "capitalize !important",
    paddingLeft: "5px",
    whiteSpace: "pre",
  },
  Bannersec: {
    background: "#f1f1f1",
    "& p": {
      fontFamily: "Poppins",
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "15px",
      letterSpacing: "0em",
      textAlign: "left",
    },
    "& h6": {
      fontFamily: "Poppins",
      fontSize: "10px",
      fontWeight: "600",
      lineHeight: "15px",
      letterSpacing: "0em",
      textAlign: "left",
    },
  },
  maincardBox: {
    border: "1px solid #D9D9D9",
  },
  buttonClass: {
    color: "#FFD260",
    fontFamily: "Poppins",
    background: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    letterSpacing: "2.4px",
    textTransform: "uppercase",
    padding: "15px 15px",
    "@media(max-width:767px)": {
      width: "100%",
    },
  },
}));
const list = [
  {
    imgae: "images/apartment1.png",
    title: "Fire Safety",
  },
  {
    imgae: "images/apartment2.png",
    title: "Gated Compound",
  },
  {
    imgae: "images/apartment3.png",
    title: "Modular Kitchen",
  },
  {
    imgae: "images/apartment1.png",
    title: "Registry & Loan Availaible",
  },
  {
    imgae: "images/apartment2.png",
    title: "2 Source Of Water 24*7",
  },
  {
    imgae: "images/apartment3.png",
    title: "Branded Lift",
  },
  {
    imgae: "images/apartment1.png",
    title: "Registry & Loan Availaible",
  },
  {
    imgae: "images/apartment2.png",
    title: "2 Source Of Water 24*7",
  },
  {
    imgae: "images/apartment3.png",
    title: "Branded Lift",
  },
];
//   {
//     name: "Mandi",
//   },
//   {
//     name: "Chattarpur",
//   },
//   {
//     name: "Ghitorni",
//   },
//   {
//     name: "South Delhi",
//   },
//   {
//     name: "Ghitorni",
//   },

//   //
//   //
//   //
//   //
// ];
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        color: "#000",
        borderRadius: "100%",
      }}
      onClick={onClick}
    />
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        color: "#000",
        borderRadius: "100%",
      }}
      onClick={onClick}
    />
  );
}
export default function Hero1() {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const FaqData = [
    {
      title: "Why Choose Bhavishya Nirman Developers?",
      descrition:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”",
    },
    {
      title: "Relocating in South Delhi: Which Locations to Consider?",
      descrition:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”",
    },
    {
      title: "Why Choose Bhavishya Nirman Developers?",
      descrition:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”",
    },
  ];
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  var settings2 = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <Box className={classes.mainClass}>
      <Container>
        <Box display={"flex"} justifyContent={"center"}>
          <Box style={{ position: "relative" }} maxWidth={500}>
            <img src="images/apartment1.png" className="imgBox" alt="" />
            <Box
              className="displayCenterBox"
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                borderRadius: "2px",
                background: "#EF5B1C",
                minWidth: "105px",
                padding: "6px 10px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
              >
                <path
                  d="M0.561149 10.0372L8.42734 0.689157L6.38786 7.91804L11.0887 8.84724L2.95456 18.4476L5.26197 10.9664L0.561149 10.0372Z"
                  fill="#FFC83A"
                />
              </svg>
              <Typography className={classes.riben}>Fast Selling</Typography>
            </Box>
            <Box
              className="displayCenterBox"
              style={{
                position: "absolute",
                right: "0px",
                bottom: "-10px",
                borderRadius: "2px",
                background: "#000",
                minWidth: "65px",
                border: "1px solid #fff",
                padding: "6px 10px",
              }}
            >
              <img src="/images/Group 11.png" />
              &nbsp;
              <Typography className={classes.riben}>8</Typography>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box className={classes.content} maxWidth={500}>
            <Box className="displaySpaceBetween">
              <Typography variant="h6">Daisy Appartment T1</Typography>
            </Box>
            <Typography variant="body1">Chattarpur Enclave Phase 2</Typography>
            <Typography variant="h5">
              Price starting from 45 Lacs* Onwards
            </Typography>
          </Box>
        </Box>
        <Box mt={2} py={3} className={classes.Bannersec}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ borderRight: "1px solid #A0A0A0" }}>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box display={"flex"}>
                  <Box>
                    <img
                      src="\images\image 5.png"
                      style={{ marginTop: "-8px" }}
                    />
                  </Box>{" "}
                  &nbsp;&nbsp;
                  <Box>
                    <Typography variant="body1">Configuration</Typography>
                    <Typography variant="h6">
                      4 BHK independent floors
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box display={"flex"}>
                  <Box>
                    <img
                      src="\images\image 6.png"
                      style={{ marginTop: "-8px" }}
                    />
                  </Box>{" "}
                  &nbsp;&nbsp;
                  <Box>
                    <Typography variant="body1">Under Construction</Typography>
                    <Typography variant="h6">Possession: 31/12/2026</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          style={{ borderTop: "1px solid rgb(160 160 160 / 26%)" }}
          py={3}
          className={classes.Bannersec}
        >
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ borderRight: "1px solid #A0A0A0" }}>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box display={"flex"} p={"0 5px 0 15px"}>
                  <Box>
                    <img
                      src="\images\image 7.png"
                      style={{ marginTop: "-8px" }}
                    />
                  </Box>{" "}
                  &nbsp;&nbsp;
                  <Box>
                    <Typography variant="body1">
                      Unit Size (Carpet Area)
                    </Typography>
                    <Typography variant="h6">
                      126.44 - 194.08 Sq. Mtrs.1,361 - 2,089 Sq. Ft.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={6}>
              {" "}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box display={"flex"}>
                  <Box>
                    <img
                      src="\images\image 8 (1).png"
                      style={{ marginTop: "-8px" }}
                    />
                  </Box>{" "}
                  &nbsp;&nbsp;
                  <Box>
                    <Typography variant="body1">Project Area</Typography>
                    <Typography variant="h6">
                      Part of 75 Acres Township
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: "400",
              color: "#535353",
              lineHeight: "19px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
            variant="body1"
          >
            Reserve what you deserve at Samsara Avasa by Adani Realty & Brahma
            Group. This luxurious property offering 4 BHK independent floors in
            Sector 63, Gurugram is an address which is a true expression of
            yourself.
          </Typography>
        </Box>
        <Box p={1} mt={5} mb={3}>
          <Heading title="Amenities We Offer" />
        </Box>
        <Slider {...settings}>
          {list.map((item, i) => {
            return (
              <Box textAlign="center" className="displayCenter">
                <Avatar
                  src={item.imgae}
                  sx={{ width: "94px", height: "94px" }}
                />
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            );
          })}
        </Slider>
        <Box p={1} mt={5} mb={3}>
          <Heading title="Unit Plans" />
        </Box>
        <Slider {...settings2} style={{ padding: "0px !important" }}>
          {list.map((item, i) => {
            return (
              <Box>
                <Box
                  m={1}
                  className={classes.maincardBox}
                  onClick={handleClickOpen}
                >
                  <Box p={2}>
                    <Typography>4BHK + Utility</Typography>
                    <Typography>126.44 - 194.08 Sq. Mtrs</Typography>
                    <img src="images/mix.png" alt="" width="100%" />
                  </Box>
                  <Box
                    py={1}
                    display="flex"
                    justifyContent="center"
                    style={{ background: "#FFF5DB" }}
                  >
                    <Typography>Specifications</Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Slider>
        <DialogComponent
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          setOpen={setOpen}
        />
      </Container>

      <Box mt={5} mb={3}>
        <Container>
          <Heading title="Master Layout" />
          <Box display={"flex"} justifyContent="center">
            <Box mt={2} maxWidth={500}>
              <img src="images/green.png" width="100%" alt="" />
            </Box>
          </Box>
        </Container>
        <Box className={classes.contentBg}>
          <Typography variant="h6">Loan and EMI Available. </Typography>
          <Typography variant="h5">Call us to discuss the offers.</Typography>
          <Box mt={1} width="100%" display={"flex"} justifyContent={"center"}>
            <Button
              className={classes.buttonClass}
              variant="contained"
              color="primary"
              startIcon={<Phone />}
            >
              schedule a meeting
            </Button>
          </Box>
        </Box>
        <Box p={1} mt={5} mb={3}>
          <Container>
            <Heading title="Similar Projects" />
            <Slider {...settings2} style={{ padding: "0px !important" }}>
              {locationSouthdelhi.map((item, i) => (
                <Box mt={3} key={i}>
                  <ApartMentCard type="HERO" item={item} i={i} />
                </Box>
              ))}
            </Slider>
          </Container>
        </Box>
        <Box>
          <Container>
            <Box>
              <Typography variant="h5" style={{}}>
                FAQ’s
              </Typography>
            </Box>

            {FaqData.map((data, index) => {
              return <AcoordianComponent data={data} />;
            })}
            <Box mt={5}>
              <Contact />
            </Box>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "18px",
                color: "#888888",
                fontFamily: "Poppins",
                textAlign: "justify",
              }}
            >
              “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper
              fringilla.uris faucibus lectus a hendrerit vehicula. Mauris
              commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum
              quis est a pretium.”“Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem
              nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit
              vehicula. Mauris commodo ante ex, ac pharetra orci auctor
              sollicitudin. In dictum quis est a pretium.”
            </Typography>
          </Container>
          <Box mt={2}>
            <ExplorePropety type={"ExplorePropety"} />
            <Achivement type={"Achivement"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import Apiconfigs from "../../ApiConfig/ApiConfig";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const DataTable = () => {
  const [_propertyname, setPropertyName] = useState("");
  const navigate = useNavigate();
  const blogList = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.listProperty,
        method: "GET",
      });
      if (res) {
        console.log("msfsksfd8f878--->", res);
        setPropertyName(res?.data?.result?.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DeleteBlog = async (id) => {
    try {
      const res = await axios({
        url: Apiconfigs.deleteProperty,
        method: "DELETE",
        headers: {
          token: sessionStorage.getItem("token")
            // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGUzMDdmNTY5MmMwNzMxNTQ2OTg1NGIiLCJlbWFpbCI6InZpc2hudUBtYWlsaW5hdG9yLmNvbSIsIm1vYmlsZU51bWJlciI6Ijg1MjE1Mjk1NjUiLCJ1c2VyVHlwZSI6IkFETUlOIiwiaWF0IjoxNjkyNjE1MzQ3LCJleHAiOjE2OTI3MDE3NDd9.vQpGVPSM5b5DYycU-VezpBc5ErMeL2NkCgHzIlsAeYs",
        },
        params: {
          propertyId: id,
        },
      });
      if (res) {
        toast.success("blog deleted successfully.");
        blogList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    blogList();
  }, []);

  return (
    <Box mt={10}>
      <Container>
        <Box display={"flex"} justifyContent={"end"}>
          <Button
            onClick={() => navigate("/add-property")}
            style={{
              padding: "9px 30px",
              color: "#FFC83A",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "capitalize",
              border: "2px solid #8080803b",
            }}
          >
            Add Property
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>index</TableCell>

                <TableCell>Title</TableCell>
                <TableCell>Short Description</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_propertyname &&
                _propertyname?.map((data, index) => {
                  console.log("data---a++>", data);
                  return (
                    <TableRow
                      key={index}
                      style={
                        index % 2 == 0
                          ? {
                              background: "#6495ed47",
                              borderBottom: "2px solid #808080ba",
                            }
                          : { borderBottom: "2px solid #808080ba" }
                      }
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{data?.title}</TableCell>
                      <TableCell>{data?.short_description}</TableCell>
                      <TableCell>
                        <Avatar src={data?.imageUrl} />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            DeleteBlog(data?._id);
                          }}
                        >
                          <DeleteForeverIcon style={{ color: "red" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default DataTable;

import { Avatar, Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import Heading from "../../component/Heading";
import Slider from "react-slick";
import { DefaultPlayer as Video } from "react-html5video";
import CustomButton from "./CustomButton";
import { VideoPlayer } from "../../component/VideoPlayer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  CardBox: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
}));

export default function CustomerTestomonial() {
  const classes = useStyles();

  const list = [
    {
      profilepic: "images/apartment1.png",
      name: "Rajesh Chaudhary",
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”",
      url: "https://www.youtube.com/watch?v=EuA6cgy4DYE&t=605s",
    },
    {
      profilepic: "images/apartment2.png",
      name: "Rajesh Chaudhary",
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”",
      url: "https://www.youtube.com/watch?v=mfoNVNjUB4s&t=346s",
    },
    {
      profilepic: "images/apartment3.png",
      name: "Rajesh Chaudhary",
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”",
      url: "https://www.youtube.com/watch?v=7kRKKMbjUvE&t=31s",
    },
    {
      profilepic: "images/apartment4.png",
      name: "Rajesh Chaudhary",
      content:
        "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed rhoncus finibus fringilla. Morbi blandit sem nec nunc ullamcorper fringilla.uris faucibus lectus a hendrerit vehicula. Mauris commodo ante ex, ac pharetra orci auctor sollicitudin. In dictum quis est a pretium.”",
      url: "https://www.youtube.com/watch?v=7kRKKMbjUvE&t=31s",
    },
  ];

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    // autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container style={{ overflow: "hidden" }}>
      <Box mt={7}>
        <Heading title="Customers Testimonials" />
      </Box>
      <Slider {...settings}>
        {list.map((item, i) => {
          return (
            <Box m={"10px"}>
              <Box
                sx={{
                  padding: "26px 10px 10px",
                  paddingTop: "26px",
                  margin: "10px",
                }}
                className={classes.CardBox}
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={item.profilepic}
                    sx={{ width: "38px", height: "38px" }}
                  />
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      paddingLeft: "12px",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{
                      padding: "22px 0px 10px",
                      color: "#888",
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}
                  >
                    {item.content}
                  </Typography>
                </Box>
                <Box>
                  {/* <Video
                  loop
                  style={{ width: "100%" }}
                  muted
                  controls={["PlayPause", "Seek", "Time", "Volume"]}
                  poster="http://sourceposter.jpg"
                  onCanPlayThrough={() => {
                    // Do stuff
                  }}
                >
                  <source src={item.url} type="video/webm" />
                  <track
                    label="English"
                    kind="subtitles"
                    srcLang="en"
                    src="http://source.vtt"
                    default
                  />
                </Video> */}
                  <VideoPlayer url={item.url} />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Slider>
      <Box sx={{ padding: "26px 0px 60px" }} className="displayCenterBox">
        <CustomButton btnName="Explore more Videos" />
      </Box>
    </Container>
  );
}

import React from "react";
import { Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
const AcoordianComponent = ({ data, type }) => {
  //   const theme = createMuiTheme({
  //     overrides: {

  //     },
  //   });
  return (
    <Box>
      <Accordion style={{ borderBottom: "1px solid #e2e2e2 !important" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "gray" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="body2"
            style={
              type == "Footer" ? { fontSize: "16px", fontWeight: "600" } : {}
            }
          >
            {type == "Footer" ? <> {data?.mainName}</> : <>{data?.title}</>}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {type == "Footer" ? (
            <>
              {data.childName?.map((data) => {
                return (
                  <Typography
                    variant="body2"
                    style={
                      type == "Footer"
                        ? {
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "10px 0",
                          }
                        : {
                            fontSize: "12px",
                            color: "#888888",
                            textAlign: "justify",
                            fontFamily: "Poppins",
                          }
                    }
                  >
                    {data?.name}
                  </Typography>
                );
              })}
            </>
          ) : (
            <>
              {" "}
              <Typography
                variant="body2"
                style={{
                  fontSize: "12px",
                  color: "#888888",
                  textAlign: "justify",
                  fontFamily: "Poppins",
                }}
              >
                {data?.descrition}
              </Typography>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AcoordianComponent;

import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Heading from "../../component/Heading";
import Slider from "react-slick";

import "react-html5video/dist/styles.css";
import { VideoPlayer } from "../../component/VideoPlayer";
const useStyles = makeStyles((theme) => ({
  mainWalik: {
    "& h4": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
  CardBox: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
}));
export default function WalkthrughVideo() {
  const classes = useStyles();
  const testimonials = [
    {
      id: 1,
      name: "Daisy Apartment T1 walkthrough",
      videoUrl: "https://www.youtube.com/watch?v=EuA6cgy4DYE&t=605s",
      name1: "Chattarpur Enclave Phase 2",
    },
    {
      id: 2,
      name: "Mangolia Apartments walkthrough",
      videoUrl: "https://www.youtube.com/watch?v=mfoNVNjUB4s&t=346s",
      name1: "Chattarpur Enclave Phase 2",
    },
    {
      id: 2,
      name: "Mangolia Apartments walkthrough",
      videoUrl: "https://www.youtube.com/watch?v=7kRKKMbjUvE&t=31s",
      name1: "Chattarpur Enclave Phase 2",
    },
    {
      id: 2,
      name: "Mangolia Apartments walkthrough",
      videoUrl:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Daisy%20Cover%20Video%2Fsurrounding-1_HvY8mSl8.mp4?alt=media&token=c48c2d7c-40ad-49a6-b08f-c071ea27d461",
      name1: "Chattarpur Enclave Phase 2",
    },
    {
      id: 2,
      name: "Mangolia Apartments walkthrough",
      videoUrl: "https://www.youtube.com/watch?v=7kRKKMbjUvE&t=31s",
      name1: "Chattarpur Enclave Phase 2",
    },
    // Add more testimonial objects here
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: true,
    centerMode: true,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          initialSlide: 5,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container style={{ overflow: "hidden" }}>
      <Box className={classes.mainWalik} mt={3}>
        <Box mb={3} mt={6}>
          <Heading title="Our Properties" />
          <Box mt={2}>
            <Heading title="Walkthrough Videos" />
          </Box>
        </Box>

        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <Box m={"10px"}>
              <Box
                key={testimonial.id}
                sx={{
                  padding: "10px 10px",
                  margin: "10px",
                }}
                className={classes.CardBox}
              >
                <VideoPlayer url={testimonial.videoUrl} />
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "'Poppins',serif",
                    fontSize: "14px !important",
                    fontStyle: "normal",
                    fontWeight: "500 !important",
                    lineHeight: "normal",
                    padding: "10px 0px 5px",
                  }}
                >
                  {testimonial.name}
                </Typography>
                <Typography
                  sx={{
                    color: "#777",
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  {testimonial.name1}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Container>
  );
}

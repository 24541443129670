import React from "react";
import Banner from "./Banner";
import TabButton from "./TabButton";
import WalkthrughVideo from "./WalkthrughVideo";
import Achivement from "./Achivement";
import AmentiesOffer from "./AmentiesOffer";
import WhyChooseUs from "./WhyChooseUs";
import CustomerTestomonial from "./CustomerTestomonial";
import ExplorePropety from "./ExplorePropety";
import BlogSection from "./BlogSection";
import ContactUs from "./ContactUs";
import { Box, Container } from "@mui/material";

export default function Home1() {
  const AllComponent = () => {
    return (
      <>
        <Banner />
        <Container>
          <Box id="project">
            <TabButton />
          </Box>
        </Container>
        <ExplorePropety />
        <WalkthrughVideo />
        <Achivement />
        <AmentiesOffer />
        <CustomerTestomonial />
        <WhyChooseUs />
        <BlogSection />
        <ContactUs />
        {/* <div>
          <a href="/flatsinchattarpur">
            <button>Click Here</button>
          </a>
        </div>
        <div>
          <a href="/projects">
            <button>Our Properties</button>
          </a>
        </div> */}
      </>
    );
  };
  return (
    <>
      <AllComponent />
    </>
  );
}

import React from "react";
import AddEditBlog from "../Firebase/AddEditBlog";
import PageNotFound from "../component/PageNotFound";
// import Flatsinchattarpur from "../Innerpages/Hero9";
import Projects from "../Otherpage/Projects";
import Contact from "../Otherpage/Contact";
import Aboutus from "../Otherpage/Aboutus";
import Home1 from "../pages/banner/Home1";
import Daisy1 from "../Propertypage.js/Daisy1";
import Daisy2 from "../Propertypage.js/Daisy2";
import Lotus from "../Propertypage.js/Lotus";
import Magnolia from "../Propertypage.js/Magnolia";
import Lily from "../Propertypage.js/Lily";
import jasmine from "../Propertypage.js/Jasmine";
import Iris from "../Propertypage.js/Iris";
import Orchid from "../Propertypage.js/Orchid";
import Hero1 from "../Propertypage.js/Hero1";
import Login from "../Homelayout/LoginLayout/Login";
import AddProperties from "../pages/Properties/AddProperties";
import ViewProperties from "../pages/Properties/ViewProperties";
import ListProperties from "../pages/Properties/ListProperties";
const HomePage = React.lazy(() => import("../pages/banner/Home1"));
const HomeLayout = React.lazy(() => import("../Homelayout"));
const BlogHome = React.lazy(() => import("../pages/Home"));
const TagBlog = React.lazy(() => import("../pages/TagBlog"));
const Detail = React.lazy(() => import("../pages/Detail"));
const LoginLayout = React.lazy(() => import("../Homelayout/LoginLayout/index"));
const routes = [
  {
    path: "/",
    element: HomePage,
    layout: HomeLayout,
  },
  {
    path: "/",
    element: Home1,
    layout: HomeLayout,
  },
  {
    path: "/add-blog",
    element: AddEditBlog,
    layout: HomeLayout,
  },

  {
    path: "/blogs",
    element: BlogHome,
    layout: HomeLayout,
  },
  {
    path: "/dbd",
    element: Hero1,
    layout: HomeLayout,
  },
  {
    path: "/admin-login",
    element: Login,
    layout: LoginLayout,
  },
  {
    path: "/tag/:tag",
    element: TagBlog,
    layout: HomeLayout,
  },
  {
    path: "/detail/:id",
    element: Detail,
    layout: HomeLayout,
  },
  {
    path: "/add-property",
    element: AddProperties,
    layout: HomeLayout,
  },
  {
    path: "/view-property",
    element: ViewProperties,
    layout: HomeLayout,
  },
  {
    path: "/-",
    element: ViewProperties,
    layout: HomeLayout,
  },
  {
    path: "/admin-list-blogs",
    element: ListProperties,
    layout: HomeLayout,
  },
  {
    path: "/404",
    element: PageNotFound,
    layout: HomeLayout,
  },
  // {
  //   path: "/flatsinchattarpur",
  //   element: Flatsinchattarpur,
  //   layout: HomeLayout,
  // },
  {
    path: "/about",
    element: Aboutus,
    layout: HomeLayout,
  },
  {
    path: "/contact",
    element: Contact,
    layout: HomeLayout,
  },
  {
    path: "/projects",
    element: Projects,
    layout: HomeLayout,
  },
  {
    path: "/daisy-detail",
    element: Daisy1,
    layout: HomeLayout,
  },
  {
    path: "/daisy-detail-property",
    element: Daisy2,
    layout: HomeLayout,
  },
  {
    path: "/iris-detail-property",
    element: Iris,
    layout: HomeLayout,
  },
  {
    path: "/jasmine-detail-property",
    element: jasmine,
    layout: HomeLayout,
  },
  {
    path: "/lily-detail-property",
    element: Lily,
    layout: HomeLayout,
  },
  {
    path: "/lotus-detail-property",
    element: Lotus,
    layout: HomeLayout,
  },
  {
    path: "/magnolia-detail-property",
    element: Magnolia,
    layout: HomeLayout,
  },
];
// {
//   path: "/faq",
//   element: FAQ,
//   layout: HomeLayout,
// },

export default routes;

import { Box, Typography } from "@mui/material";
import React from "react";

export default function Heading({ title }) {
  return (
    <Box>
      <Typography
        sx={{
          color: "#000",

          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "normal",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

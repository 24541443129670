import React, { useState, useEffect, useRef } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
// import { db, storage } from "./FirebaseConfig";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import JoditEditor from "jodit-react";
import {
  addDoc,
  collection,
  getDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-hot-toast";
import axios from "axios";
import Apiconfigs from "../../ApiConfig/ApiConfig";

const AddProperties = ({ user, setActive }) => {
  const [progress, setProgress] = useState(false);
  const editor = useRef(null);
  const [_logndescription, setLongDescription] = useState();
  const [_title, setTitle] = useState("");
  const [_shoirtTitle, setShortTitle] = useState("");
  const [_image, setImage] = useState("");
  const navigate = useNavigate();

  const ImageUpload = async (value) => {
    const formData = new FormData();
    formData.append("uploaded_file", value);
    try {
      setProgress(true);
      const res = await axios({
        url: Apiconfigs.uploadFile,
        method: "POST",
        headers: {
          token: sessionStorage.getItem("token"),
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGUzMDdmNTY5MmMwNzMxNTQ2OTg1NGIiLCJlbWFpbCI6InZpc2hudUBtYWlsaW5hdG9yLmNvbSIsIm1vYmlsZU51bWJlciI6Ijg1MjE1Mjk1NjUiLCJ1c2VyVHlwZSI6IkFETUlOIiwiaWF0IjoxNjkyNjE1MzQ3LCJleHAiOjE2OTI3MDE3NDd9.vQpGVPSM5b5DYycU-VezpBc5ErMeL2NkCgHzIlsAeYs",
        },
        data: formData,
      });
      if (res) {
        setProgress(false);

        setImage(res?.data?.result?.secure_url);
      }
    } catch (error) {
      setProgress(false);

      console.log("error", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      _title != "" &&
      _image != "" &&
      _shoirtTitle != "" &&
      _logndescription != ""
    ) {
      try {
        setProgress(true);

        const res = await axios({
          url: Apiconfigs.addProperty,
          headers: {
            token: sessionStorage.getItem("token"),
            //"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGUzMDdmNTY5MmMwNzMxNTQ2OTg1NGIiLCJlbWFpbCI6InZpc2hudUBtYWlsaW5hdG9yLmNvbSIsIm1vYmlsZU51bWJlciI6Ijg1MjE1Mjk1NjUiLCJ1c2VyVHlwZSI6IkFETUlOIiwiaWF0IjoxNjkyNjE1MzQ3LCJleHAiOjE2OTI3MDE3NDd9.vQpGVPSM5b5DYycU-VezpBc5ErMeL2NkCgHzIlsAeYs",
          },
          method: "POST",
          data: {
            title: _title,
            imageUrl: _image,
            short_description: _shoirtTitle,
            description: _logndescription,
          },
        });
        if (res) {
          setProgress(false);

          navigate("/admin-list-blogs");
        }
      } catch (error) {
        setProgress(false);

        console.log("error", error);
      }
    } else {
      toast.error("PLease fill the all fields");
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem("token") == null) {
      navigate("/");
    }
  }, []);

  return (
    <Box mt={5}>
      {sessionStorage.getItem("token") != null && (
        <div className="container-fluid mb-4">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div
                className="col-10 col-md-8 col-lg-6"
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  marginTop: "50px",
                }}
              >
                <form
                  className="row blog-form contact_form"
                  onSubmit={handleSubmit}
                >
                  <Box>
                    <Box mt={1} mb={1}>
                      <Typography variant="h5">Property title name</Typography>
                    </Box>
                    <Box mt={1} mb={1}>
                      <Typography variant="body1">Title name</Typography>
                    </Box>
                    <TextField
                      type="text"
                      disabled={progress}
                      fullWidth
                      placeholder="enter title name"
                      name="title"
                      value={_title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Box>

                  <Box mt={2}>
                    <Box mt={1} mb={1}>
                      <Typography variant="body1">
                        Title _logndescription
                      </Typography>
                    </Box>
                    <TextField
                      disabled={progress}
                      type="text"
                      fullWidth
                      placeholder="Title _logndescription"
                      name="title"
                      value={_shoirtTitle}
                      onChange={(e) => setShortTitle(e.target.value)}
                    />
                  </Box>
                  <Box mt={2} mb={1}>
                    <Box mt={1} mb={1}>
                      <Typography variant="body1">
                        Upload the property image
                      </Typography>
                    </Box>
                    <input
                      type="file"
                      disabled={progress}
                      value={""}
                      onChange={(e) => ImageUpload(e.target.files[0])}
                      fullWidth
                      style={{
                        border: "1px solid grey",
                        padding: "10px",
                        borderRadius: "2px",
                        width: "100%",
                      }}
                    />
                  </Box>
                  <Box mt={3}>
                    <Box mt={1} mb={1}>
                      <Typography variant="body1">
                        Description of title
                      </Typography>
                    </Box>
                    <JoditEditor
                      disabled={progress}
                      ref={editor}
                      value={_logndescription}
                      variant="outlined"
                      name="descritionValue"
                      fullWidth
                      size="small"
                      tabIndex={1}
                      onChange={(e) => setLongDescription(e)}
                      onBlur={(e) => setLongDescription(e)}
                    />
                  </Box>

                  <div className="col-12 py-3 text-center">
                    <button
                      className="btn btn-add"
                      type="submit"
                      disabled={progress}
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default AddProperties;

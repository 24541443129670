import React, { useState, useEffect, useRef } from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { db, storage } from "./FirebaseConfig";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import JoditEditor from "jodit-react";
import {
  addDoc,
  collection,
  getDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from "firebase/firestore";
// import { useNavigate } from "react-router-dom";

import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { ClassNames } from "@emotion/react";
import { makeStyles } from "@mui/styles";
const initialState = {
  title: "",
  tags: [],
  trending: "no",
  category: "",
  description: "",
  comments: [],
  likes: [],
};

const categoryOption = [
  "Fashion",
  "Technology",
  "Food",
  "Politics",
  "Sports",
  "Business",
];
const useStyles = makeStyles((theme) => ({
  CardBox: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    minHeight: "440px",
    padding: "10px",
    marginTop: "50px",
  },
}));
const AddEditBlog = ({ user, setActive }) => {
  const classes = useStyles();
  const [form, setForm] = useState(initialState);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const editor = useRef(null);
  const { id } = useParams();
  const [description, setDescription] = useState();
  const navigate = useNavigate();

  const { title, tags, category, trending } = form;

  useEffect(() => {
    if (sessionStorage.getItem("token") == null) {
      navigate("/");
    } else {
      const uploadFile = () => {
        const storageRef = ref(storage, file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            setProgress(progress);
            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
              default:
                break;
            }
          },
          (error) => {
            console.log(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
              // toast.info("Image upload to firebase successfully");
              setForm((prev) => ({ ...prev, imgUrl: downloadUrl }));
            });
          }
        );
      };

      file && uploadFile();
    }
  }, [file]);

  useEffect(() => {
    id && getBlogDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getBlogDetail = async () => {
    const docRef = doc(db, "blogs", id);
    const snapshot = await getDoc(docRef);
    if (snapshot.exists()) {
      setForm({ ...snapshot.data() });
    }
    setActive(null);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTags = (tags) => {
    setForm({ ...form, tags });
  };

  const handleTrending = (e) => {
    setForm({ ...form, trending: e.target.value });
  };

  const onCategoryChange = (e) => {
    setForm({ ...form, category: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category && tags && title && description && trending) {
      if (!id) {
        try {
          await addDoc(collection(db, "blogs"), {
            ...form,
            timestamp: serverTimestamp(),
            // author: user.displayName,
            // userId: user.uid,
          });
          toast.success("Blog created successfully");
          navigate("/");
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await updateDoc(doc(db, "blogs", id), {
            ...form,
            timestamp: serverTimestamp(),
            // author: user.displayName,
            // userId: user.uid,
          });
          alert("Blog updated successfully");
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      return alert("All fields are mandatory to fill");
    }

    // navigate("/");
  };

  return (
    <Box>
      <div className="container-fluid mb-4" style={{ paddingBottom: "500px" }}>
        <div className="container">
          <div className="col-12">
            <div className="text-center heading py-2">
              {id ? "Update Blog" : "Create Blog"}
            </div>
          </div>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-10 col-md-8 col-lg-6">
              <Box className={classes.CardBox}>
                <Typography
                  variant="body1"
                  textAlign={"center"}
                  style={{ fontSize: "22px" }}
                >
                  Add Blogs
                </Typography>
                <form
                  className="row blog-form contact_form"
                  onSubmit={handleSubmit}
                >
                  <Box>
                    <TextField
                      type="text"
                      fullWidth
                      placeholder="Title"
                      name="title"
                      value={title}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box width="100%" mt={1}>
                    <ReactTagInput
                      tags={tags}
                      placeholder="Tags"
                      onChange={handleTags}
                    />
                  </Box>

                  <Box mt={1}>
                    <Typography variant="h5" className="trending">
                      Is it trending blog ?
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={trending}
                        onChange={handleTrending}
                        color="secondary"
                      >
                        <FormControlLabel
                          value="yes"
                          color="secondary"
                          control={<Radio color="secondary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="no"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <div className="col-12 py-3">
                    <Select
                      value={category}
                      onChange={onCategoryChange}
                      className="catg-dropdown"
                    >
                      <MenuItem>Please select category</MenuItem>
                      {categoryOption.map((option, index) => (
                        <MenuItem value={option || ""} key={index}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div>
                    <JoditEditor
                      ref={editor}
                      value={description}
                      variant="outlined"
                      name="descritionValue"
                      fullWidth
                      size="small"
                      tabIndex={1}
                      onChange={(e) => setDescription(e)}
                      onBlur={(e) => setDescription(e)}
                    />
                  </div>
                  <div className="mb-3 mt-2">
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <div className="col-12 py-3 text-center">
                    <button
                      className="btn btn-add"
                      type="submit"
                      disabled={progress !== null && progress < 100}
                    >
                      {id ? "Update" : "Submit"}
                    </button>
                  </div>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default AddEditBlog;

import {
  Box,
  Container,
  Button,
  Grid,
  Typography,
  Hidden,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Heading from "../../component/Heading";
import { Phone } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  mainSection: {
    "& h5": {
      color: "#000",
      textAlign: "center ",
      fontFamily: "Inter",
      fontSize: "96px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "51px",
      paddingBottom: "10px",
      "@media(max-width:1200px)": {
        fontSize: "60px",
      },
    },
    "& h6": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "51px",
    },
  },
  contentBg: {
    background: "#FFD260",
    padding: "28px 31px",
    marginTop: "34px",
    "& h6": {
      color: "#000",
      textAlign: "center",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    "& h5": {
      color: "#000",
      textAlign: "center",
      fontFamily: "'Poppins',serif !important",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textTransform: "capitalize",
    },
    "& button": {
      color: "#FFD260",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      letterSpacing: "2.4px",
      textTransform: "uppercase",
      padding: "15px 15px",
    },
  },
  buttonClass: {
    "@media(max-width:767px)": {
      width: "100%",
    },
  },
}));
const list = [
  {
    year: "19+",
    title: "YEARS OF EXCELLENCE",
  },
  {
    year: "1,800+",
    title: "HAPPY CUSTOMERS",
  },
  {
    year: "100%",
    title: "SATISFACTORY",
  },
];
export default function Achivement({ type }) {
  const classes = useStyles();
  return (
    <>
      <Container className={classes.mainSection}>
        {type != "Achivement" && (
          <>
            <Box mb={2} mt={5}>
              <Heading title="Our Achievements" />
            </Box>
            <Grid container spacing={2}>
              {list.map((item, i) => {
                return (
                  <>
                    <Hidden smDown>
                      <Grid item xs={12} sm={12} md={4}>
                        <Box textAlign="center">
                          <ListData item={item} i={i} />
                        </Box>
                      </Grid>
                    </Hidden>
                  </>
                );
              })}
            </Grid>
            <Hidden smUp>
              <Box mt={3} sx={{ background: "#000" }}>
                {list.map((item, i) => {
                  return (
                    <Box item xs={12} sm={12} md={12}>
                      <ListData item={item} i={i} type="box" />
                    </Box>
                  );
                })}
              </Box>
            </Hidden>
          </>
        )}
      </Container>
      <Box className={classes.contentBg}>
        <Typography variant="h6">Interested to know More?</Typography>
        <Typography variant="h5">Give us a missed Call on</Typography>
        <Box mt={2} width="100%" display={"flex"} justifyContent={"center"}>
          <Button
            className={classes.buttonClass}
            variant="contained"
            color="primary"
            startIcon={<Phone />}
          >
            98995-50700
          </Button>
        </Box>
      </Box>
    </>
  );
}

const ListData = ({ item, i, type }) => {
  return (
    <>
      <Box
        sx={
          type === "box"
            ? {
                display: "flex",
                borderRadius: "2px",
                alignItems: "center",
                padding: "20px 30px",
              }
            : {
                borderRadius: "4px",
                background: "#D9D9D9",
                padding: "55px 50px",
                height: "273px",
              }
        }
      >
        <Typography
          style={
            type === "box"
              ? {
                  color: "#FFD260",
                  textAlign: "center",
                  fontFamily: "Inter",
                  fontSize: "40px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "51px",
                }
              : {}
          }
          variant="h5"
        >
          {item.year}
        </Typography>
        <Typography
          style={
            type === "box"
              ? {
                  color: "#FFF",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20.5px",
                  paddingLeft: "10px",
                  wordBreak: "break-word",
                  width: "100%",
                  maxWidth: "140px",
                }
              : {}
          }
          variant="h6"
        >
          {item.title}
        </Typography>
      </Box>
      <Box sx={{ padding: "0px 30px", width: "100%" }}>
        {type === "box" && (
          <Box
            sx={{
              height: "2px",
              width: "100%",
              // width: "276px",
              background: "rgba(255, 255, 255, 0.20);",
            }}
          ></Box>
        )}
      </Box>
    </>
  );
};

import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  content: {
    padding: "0px 5px",
    "& h6": {
      color: "#000",
      paddingTop: "23px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    "& p": {
      color: "#777",
      padding: "10px 0px 8px",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    "& h5": {
      color: "#383838",

      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
  },
  seeMore: {
    borderRadius: "2px",
    border: "1px solid #FFD260",
    background: "rgba(255, 210, 96, 0.00)",

    width: "140px",
    padding: "10px 5px",
  },
  btnSection: {
    color: "#FFD260",
    fontSize: "12px !important",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal !important",
    letterSpacing: "2.4px",
    textTransform: "capitalize",
    padding: "13px 0px",
  },
  riben: {
    color: "#FFF !important",
    fontFamily: "Poppins",
    fontSize: "12px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important ",
    lineHeight: "normal !important",
    textTransform: "capitalize !important",
    paddingLeft: "5px",
    whiteSpace: "pre",
  },
}));
export default function ApartMentCard({ item, i }) {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Box
      onClick={() => {
        if (item.id === "1") {
          navigate("/daisy-detail");
        }
        if (item.id === "4") {
          navigate("/magnolia-detail-property");
        }
        if (item.id === "2") {
          navigate("/daisy-detail-property");
        }
        if (item.id === "3") {
          navigate("/lotus-detail-property");
        }
        if (item.id === "5") {
          navigate("/lily-detail-property");
        }
        if (item.id === "6") {
          navigate("/lily-detail-property");
        }
        if (item.id === "9") {
          navigate("/lily-detail-property");
        }
        if (item.id === "7") {
          navigate("/jasmine-detail-property");
        }
        if (item.id === "8") {
          navigate("/iris-detail-property");
        }
      }}
      key={i}
      sx={{
        margin: "0px 10px",
        border: "1px solid #FFD260",
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      <Box className="imageSlider" style={{ position: "relative" }}>
        <figure
          className="scaleImageClass1"
          style={{ maxHeight: "300px", minHeight: "300px" }}
        >
          <img src={item?.image} width="100%" alt="image" />
        </figure>
        <Box
          className="displayCenterBox"
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            borderRadius: "2px",
            background: "#EF5B1C",
            minWidth: "105px",
            padding: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="19"
            viewBox="0 0 12 19"
            fill="none"
          >
            <path
              d="M0.561149 10.0372L8.42734 0.689157L6.38786 7.91804L11.0887 8.84724L2.95456 18.4476L5.26197 10.9664L0.561149 10.0372Z"
              fill="#FFC83A"
            />
          </svg>
          <Typography className={classes.riben}>
            {item?.selltype ? item?.selltype : "Fast Selling"}{" "}
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Box className="displaySpaceBetween">
            <Typography variant="h6">{item?.title}</Typography>
            <Typography variant="h6">{item?.size}</Typography>
          </Box>
          <Typography variant="body1">{item?.Location}</Typography>
          <Typography variant="h5">
            Price starting from {item?.price} Onwards
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            sx={classes.btnSection}
          >
            BOOK NOW
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Heading from "../../component/Heading";
import Slider from "react-slick";
export default function WhyChooseUs() {
  const list = [
    {
      imgae:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/WHYCHOOSEUS%20ICONS%2F06f4d0033ee78db8a47bcbad0b2c27e8.png?alt=media&token=ee851ec9-d7f7-4f33-847d-3ae7c1ba39b2",
      title: "Guaranteed Loan",
    },
    {
      imgae:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/WHYCHOOSEUS%20ICONS%2Fa6ac4781bffffdaae70ed7de1424c70f.png?alt=media&token=56775ab5-cb6d-4313-aa1c-f03dd8532eff",
      title: "Registery Property",
    },
    {
      imgae:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/WHYCHOOSEUS%20ICONS%2F254f90c659666a771c1dbf1e97e52bb5.png?alt=media&token=5d945bdb-1eb2-47e5-815c-63ecb28495cd",
      title: "No Brokerage",
    },
    {
      imgae:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/WHYCHOOSEUS%20ICONS%2Fextinguisher.png?alt=media&token=647bb1b7-a520-428c-b36f-672338304b55",
      title: "Fire Safety",
    },
    {
      imgae:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/WHYCHOOSEUS%20ICONS%2Ftimer_3342355.png?alt=media&token=0ac3767d-46f8-4cb7-a976-3f6dce9c5bc1",
      title: "15 Days Refund Policy",
    },
    {
      imgae:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/WHYCHOOSEUS%20ICONS%2Fseo_7605295.png?alt=media&token=1a5f0e51-fbb3-4932-a76d-01f97c081332",
      title: "Transparency",
    },
    {
      imgae:
        "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/WHYCHOOSEUS%20ICONS%2Fa6ac4781bffffdaae70ed7de1424c70f.png?alt=media&token=56775ab5-cb6d-4313-aa1c-f03dd8532eff",
      title: "Hassle-Free Documentation",
    },
  ];

  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: false,
    prevArrow: false,
  };
  return (
    <Container sx={{ marginBottom: "64px" }}>
      <Box mt={2} mb={2}>
        <Heading title="Why Choose Us ?" />
      </Box>

      <Slider {...settings}>
        {list.map((item, i) => {
          return (
            <Box
              key={i}
              sx={{ padding: "0px 5px" }}
              textAlign="center"
              className="displayCenter"
            >
              <Box width="100%">
                <img src={item.imgae} width="100%" />
              </Box>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {item.title}
              </Typography>
            </Box>
          );
        })}
      </Slider>
    </Container>
  );
}

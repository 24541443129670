import { Button } from "@mui/material";
import React from "react";

export default function CustomButton({ btnName, type, submitData }) {
  return (
    <Button
      onClick={() => {
        if (submitData) {
          submitData();
        }
      }}
      variant="contained"
      width={type === "form" && "100%"}
      style={{
        padding: "9px 30px",
        color: "#FFC83A",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        textTransform: "capitalize",
        width: type === "form" && "100%",
      }}
      endIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
        >
          <path
            d="M1 1L6.27738 6.05749C6.67637 6.43986 6.68958 7.07336 6.30687 7.47201L1 13"
            stroke="#FFC83A"
          />
        </svg>
      }
    >
      {btnName}
    </Button>
  );
}

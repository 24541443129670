import { useLayoutEffect } from "react";

export const loctionssChattarpur = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Fdaisy.png?alt=media&token=4ec632b7-5e51-433f-9a94-ab745f6c1180",
    title: "Daisy Apartment T1",
    price: "₹ 45Lacs - 65Lacs",
    size: "819-1252 Sq. Ft.",
    category: "Chattarpur",
    id: "1",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Magnolia%2Fmagnolia-apartment.png?alt=media&token=11ab91ec-f180-4e84-a2cc-39748b991b46",
    title: "Magnolia Apartment",
    price: "₹ 50Lacs - 95Lacs",
    size: "890-1435 Sq. Ft.",
    category: "Chattarpur",
    id: "4",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/DaisyT2%2Fdaisy-apartment.png?alt=media&token=d27d72dd-aeba-4ebc-9587-1f29bfdbaa4d",
    title: "Daisy Apartment T2",
    price: "₹ 35Lacs - 40Lacs",
    size: "810-890 Sq. Ft.",
    category: "Chattarpur",
    id: "2",
  },

  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Flotus.png?alt=media&token=5d7d09f9-35de-4d59-ad1a-8120db06ed80",
    title: "Lotus Apartment",
    price: "₹ 45Lacs - 65Lacs",
    size: "720 - 1215 Sq. Ft.",
    category: "Chattarpur",
    id: "3",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Tower%20B.jpg?alt=media&token=b16f1c1d-0007-4d46-a001-0c6ae310617d",
    title: "Lily Tower B",
    price: "₹ 55Lacs - 60Lacs",
    size: "120 Sq. Yards",
    category: "Chattarpur",
    id: "5",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Apartment%20(Society).jpg?alt=media&token=b0e8baac-51d0-4fd2-9c55-f67fc71b713a",
    title: "Lily Tower C",
    price: "₹ 40Lacs",
    size: "90 Sq. Yards",
    category: "Chattarpur",
    id: "6",
  },
];

export const locationghitorni = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Fghitorni.png?alt=media&token=7e7cd573-5f1f-4815-8c48-4351c94357c6",
    title: "Jasmine Apartment",
    price: "₹ 3BHK | 50 Lacs ",
    category: "Ghitorni",
    size: "1035 Sq. Ft.",
    id: "7",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Firis.jpg?alt=media&token=e7cae0ed-a293-496d-936a-b5ac9c33cc6f",
    title: "Iris Apartment",
    price: "₹ 3 BHK | ₹50 Lacs ",
    size: "1125 Sq. Ft.",
    category: "Ghitorni",
    id: "8",
  },
];
export const locationMandi = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Tower%20A%20(2).jpg?alt=media&token=edd11181-9051-4b1e-be81-5f44a917e7a1",
    title: "Lily Tower A",
    price: "₹ 55Lacs - 60Lacs",
    category: "Mandi",
    size: "120 Sq. Yards",
    id: "9",
  },

  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Orchid%2FScreenshot%202023-07-01%20164411.png?alt=media&token=a03d97a6-cf76-487b-bb39-0d2010f50276",
    title: "Orchid Apartment",
    price: "₹ 39.9Lacs - 69.9Lacs",
    size: "824 - 1286 Sq. Ft.",
    category: "Mandi",
    id: "10",
  },
];
export const locationSouthdelhi = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Tower%20A%20(2).jpg?alt=media&token=edd11181-9051-4b1e-be81-5f44a917e7a1",
    title: "Lily Tower A",
    price: "₹ 55Lacs - 60Lacs",
    category: "Mandi",
    size: "120 Sq. Yards",
    id: "9",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Fdaisy.png?alt=media&token=4ec632b7-5e51-433f-9a94-ab745f6c1180",
    title: "Daisy Apartment T1",
    price: "₹ 45Lacs - 65Lacs",
    size: "819-1252 Sq. Ft.",
    category: "Chattarpur",
    id: "1",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Magnolia%2Fmagnolia-apartment.png?alt=media&token=11ab91ec-f180-4e84-a2cc-39748b991b46",
    title: "Magnolia Apartment",
    price: "₹ 50Lacs - 95Lacs",
    size: "890-1435 Sq. Ft.",
    category: "Chattarpur",
    id: "4",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/DaisyT2%2Fdaisy-apartment.png?alt=media&token=d27d72dd-aeba-4ebc-9587-1f29bfdbaa4d",
    title: "Daisy Apartment T2",
    price: "₹ 35Lacs - 40Lacs",
    size: "810-890 Sq. Ft.",
    category: "Chattarpur",
    id: "2",
  },

  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Flotus.png?alt=media&token=5d7d09f9-35de-4d59-ad1a-8120db06ed80",
    title: "Lotus Apartment",
    price: "₹ 45Lacs - 65Lacs",
    size: "720 - 1215 Sq. Ft.",
    category: "Chattarpur",
    id: "3",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Tower%20B.jpg?alt=media&token=b16f1c1d-0007-4d46-a001-0c6ae310617d",
    title: "Lily Tower B",
    price: "₹ 55Lacs - 60Lacs",
    size: "120 Sq. Yards",
    category: "Chattarpur",
    id: "5",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Apartment%20(Society).jpg?alt=media&token=b0e8baac-51d0-4fd2-9c55-f67fc71b713a",
    title: "Lily Tower C",
    price: "₹ 40Lacs",
    size: "90 Sq. Yards",
    category: "Chattarpur",
    id: "6",
  },

 
];
export const locationGurgaon = [
  

  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Orchid%2FScreenshot%202023-07-01%20164411.png?alt=media&token=a03d97a6-cf76-487b-bb39-0d2010f50276",
    title: "Orchid Apartment",
    price: "₹ 39.9Lacs - 69.9Lacs",
    size: "824 - 1286 Sq. Ft.",
    category: "Mandi",
    id: "10",
  },
];
export const projectList = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Fdaisy.png?alt=media&token=4ec632b7-5e51-433f-9a94-ab745f6c1180",
    title: "Daisy Apartment T1",
    price: "Price starting from 45 Lacs* Onwards ",
    size: "819-1252 Sq. Ft.",
    Location:"Chattarpur",
    category: "BHK3",
    id: "1",
    selltype: "Fast Selling",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Magnolia%2Fmagnolia-apartment.png?alt=media&token=11ab91ec-f180-4e84-a2cc-39748b991b46",
    title: "Magnolia Apartment",
    price: "Price starting from 50 Lacs* Onwards ",
    size: "890-1435 Sq. Ft.",
    Location:"Chattarpur",
    category: "BHK4",
    id: "4",
    selltype: "Fast Selling",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/DaisyT2%2Fdaisy-apartment.png?alt=media&token=d27d72dd-aeba-4ebc-9587-1f29bfdbaa4d",
    title: "Daisy Apartment T2",
    price: "Price starting from 35 Lacs* Onwards ",
    size: "810-890 Sq. Ft.",
    Location:"Chattarpur",
    category: "BHK2",
    id: "2",
    selltype: "Sold",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Fghitorni.png?alt=media&token=7e7cd573-5f1f-4815-8c48-4351c94357c6",
    title: "Jasmine Apartment",
    price: "Price starting from 50 Lacs* Onwards ",
    category: "BHK3",
    size: "1035 Sq. Ft.",
    Location:"Ghitorni",
    id: "7",
    selltype: "Sold",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Firis.jpg?alt=media&token=e7cae0ed-a293-496d-936a-b5ac9c33cc6f",
    title: "Iris Apartment",
    price: "Price starting from 50 Lacs* Onwards ",
    size: "1125 Sq. Ft.",
    category: "BHK3",
    Location:"Ghitorni",
    id: "8",
    selltype: "Sold",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/bestimage%2Flotus.png?alt=media&token=5d7d09f9-35de-4d59-ad1a-8120db06ed80",
    title: "Lotus Apartment",
    price: "Price starting from 45 Lacs* Onwards ",
    size: "720 - 1215 Sq. Ft.",
    Location:"Chattarpur",
    category: "BHK2",
    id: "3",
    selltype: "Sold",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Tower%20A%20(2).jpg?alt=media&token=edd11181-9051-4b1e-be81-5f44a917e7a1",
    title: "Lily Tower A",
    price: "Price starting from 55 Lacs* Onwards ",
    category: "Trending",
    Location:"Chattarpur",
    size: "120 Sq. Yards",
    id: "9",
    selltype: "New launch",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Tower%20B.jpg?alt=media&token=b16f1c1d-0007-4d46-a001-0c6ae310617d",
    title: "Lily Tower B",
    price: "Price starting from 55 Lacs* Onwards ",
    size: "120 Sq. Yards",
    Location:"Chattarpur",
    category: "BHK3",
    id: "5",
    selltype: "New launch",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Lily%2FLily%20Apartment%20(Society).jpg?alt=media&token=b0e8baac-51d0-4fd2-9c55-f67fc71b713a",
    title: "Lily Tower C",
    price: "Price starting from 40 Lacs* Onwards ",
    size: "90 Sq. Yards",
    Location:"Chattarpur",
    category: "BHK2",
    id: "6",
    selltype: "Fast Selling",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Orchid%2FScreenshot%202023-07-01%20164411.png?alt=media&token=a03d97a6-cf76-487b-bb39-0d2010f50276",
    title: "Orchid Apartment",
    price: "Price starting from 39.9 Lacs* Onwards ",
    size: "824 - 1286 Sq. Ft.",
    Location:"Mandi",
    category: "Trending",
    id: "10",
    selltype: "New launch",
  },
];
export const delhi = [
  {
    title: "3 BHK Flats in Delhi",
    description:
      "Delhi, the bustling capital of India, is renowned for its rich history, varied culture, and urban way of life. As more people and families seek for spacious, comfortable living spaces in Delhi NCR, the demand for 3 BHK flats in Delhi has grown. Delhi real estate is expected to increase in value and price in 2023, according to reports. Bhavishya Nirman Developers offers 3 bhk flats in Delhi under 50 lakhs.",
    title1: [
      {
        h1: "Here are our projects you can visit:",
      },
      {
        n1: "1. Lily Green Apartment",
        n2: "2. Daisy Apartment",
        n3: "3. Lotus Apartment",
      },
    ],
    title2: [
      {
        h1: "Spacious and Comfortable Flats: 3 BHK Flat in Delhi",
        p: "Bhavishya Nirman Developers offers all types of residential flats including 2 bhk, 3 bhk and 4 bhk flats in delhi. Our flats are very spacious, luxurious and surrounded with a green environment and farms. All rooms are good sized with three bedrooms, two bathrooms, a living room, a dining room, a kitchen, and so on. We also give you an opportunity of customization in under construction projects during pre-booking a flat and get the design of your homes according to your lifestyle and preference.",
      },
    ],
    Amenities: [
      {
        title: [
          {
            h1: "Amenities You Will Find Here:",
            n1: "1. Registry Available",
            n2: "2. Loan Available",
            n3: "2. Best Interior and Exterior Design",
            n4: "1. CCTV",
            n5: "2. Security Guard",
            n6: "2. Branded Lift",
            n1: "1. Registry Available",
            n2: "2. Loan Available",
            n3: "2. Loan Available",
            n4: "1. Registry Available",
            n5: "2. Loan Available",
            n6: "2. Loan Available",
          },
        ],
      },
    ],
  },
];

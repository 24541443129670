import { Box, Typography, Container } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "55px",
    "& h5": {
      color: "#FFC83A",
      fontFamily: "Poppins",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textTransform: "capitalize",
      paddingBottom: "10px",
    },
    "& h6": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      textTransform: "capitalize",
      paddingBottom: "8px",
    },
    "& p": {
      color: "#FFF",
      fontFamily: "Poppins",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      textTransform: "capitalize",
      paddingBottom: "8px",
    },
  },
}));

export default function Banner() {
  const classes = useStyles();
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: false,
    centerPadding: "0px",
    className: "recomended",
    autoplay: false,
    autoplaySpeed: 7000,
    infinite: false,
    autoplay: true,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
          dots: false,
          autoplay: false,
          centerMode: false,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <Box className={classes.root}>
      <Box className="afterTextBox" position="relative">
        <Slider {...settings}>
          {[
            "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Daisy%20Cover%20Video%2Fsurrounding-1_HvY8mSl8.mp4?alt=media&token=c48c2d7c-40ad-49a6-b08f-c071ea27d461",
            "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Daisy%20Cover%20Video%2Fsurrounding-1_HvY8mSl8.mp4?alt=media&token=c48c2d7c-40ad-49a6-b08f-c071ea27d461",
            "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Daisy%20Cover%20Video%2Fsurrounding-1_HvY8mSl8.mp4?alt=media&token=c48c2d7c-40ad-49a6-b08f-c071ea27d461",
            "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Daisy%20Cover%20Video%2Fsurrounding-1_HvY8mSl8.mp4?alt=media&token=c48c2d7c-40ad-49a6-b08f-c071ea27d461",
            "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Daisy%20Cover%20Video%2Fsurrounding-1_HvY8mSl8.mp4?alt=media&token=c48c2d7c-40ad-49a6-b08f-c071ea27d461",
            "https://firebasestorage.googleapis.com/v0/b/bn-developers-e3987.appspot.com/o/Daisy%20Cover%20Video%2Fsurrounding-1_HvY8mSl8.mp4?alt=media&token=c48c2d7c-40ad-49a6-b08f-c071ea27d461",
          ].map((item, i) => {
            return (
              <Box key={i} className="imageSlider">
                <video
                  style={{ width: "100%" }}
                  src={item}
                  autoPlay
                  loop
                  muted
                />
                <Container>
                  <Box className="leftText">
                    <Typography variant="h6">
                      Welcome to Bhavishya Nirman Developers
                    </Typography>

                    <Typography variant="h5">
                      Offering 2 BHK, 3 BHK & 4 BHK Flats in Chattarpur
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ wordBreak: "break-word" }}
                    >
                      Deals in all kinds of 2 BHK, 3 BHK & 4 BHK flats in
                      Chattarpur, South Delhi. Book Your Dream Home With Zero%
                      Brokerage
                    </Typography>
                  </Box>
                </Container>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
}

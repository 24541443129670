import React, { Fragment, Suspense } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";

import routes from "./routes";
import CoreLayout from "./CoreLayout";
import { Typography } from "@mui/material";

const renderRoute = () => {
  return routes.map((route, i) => {
    let { element: Element, meta, path, redirect, layout } = route;
    const Layout = route.layout || Fragment;
    return (
      <>
        <Route
          key={i}
          path={path}
          element={
            <Layout>
              <Suspense
                fallback={
                  <Typography textAlign="center" style={{ color: "red" }}>
                    Loading,.......
                  </Typography>
                }
              >
                <Element />
              </Suspense>
            </Layout>
          }
        />
      </>
    );
  });
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<CoreLayout />}>
      {renderRoute()}

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Route>
  )
);
export default router;

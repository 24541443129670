import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import Slider from "react-slick";
import ApartMentCard from "../../component/ApartMentCard";

const useStyles = makeStyles((theme) => ({
  content: {
    "& h6": {
      color: "#000",
      paddingTop: "23px",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    "& p": {
      color: "#777",
      padding: "10px 0px 8px",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    "& h5": {
      color: "#383838",

      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
  },
  seeMore: {
    cursor: "pointer",
    borderRadius: "2px",
    border: "1px solid #FFD260",
    background: "rgba(255, 210, 96, 0.00)",

    width: "140px",
    padding: "10px 5px",
  },
  btnSection: {
    color: "#FFD260",
    fontSize: "12px !important",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal !important",
    letterSpacing: "2.4px",
    textTransform: "capitalize",
    padding: "13px 0px",
  },
  riben: {
    color: "#FFF !important",
    fontFamily: "Poppins",
    fontSize: "12px !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important ",
    lineHeight: "normal !important",
    textTransform: "capitalize !important",
    paddingLeft: "5px",
    whiteSpace: "pre",
  },
}));
export default function SliderCard({ allData }) {
  const [viewData, setViewData] = useState({
    start: 0,
    end: 3,
  });

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: false,
    className: "recomended",
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const classes = useStyles();
  return (
    <Box mt={3}>
      <Hidden smDown>
        <Slider {...settings}>
          {allData &&
            allData.map((item, i) => {
              return (
                <Box key={i}>
                  <ApartMentCard item={item} i={i} />
                </Box>
              );
            })}
        </Slider>
      </Hidden>
      <Hidden smUp>
        <Grid container spacing={1}>
          {allData &&
            allData.slice(viewData.start, viewData.end).map((item, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <ApartMentCard item={item} i={i} />
                </Grid>
              );
            })}
          <Box mt={4} className="displayCenterBox" width="100%">
            <Box
              onClick={() => {
                if (viewData.start === 0 && viewData.end === 3) {
                  setViewData({
                    start: 0,
                    end: 9,
                  });
                } else {
                  setViewData({
                    start: 0,
                    end: 3,
                  });
                }
              }}
              className={`${classes.seeMore} displayCenterBox`}
            >
              {viewData.end === 3 ? (
                <Add style={{ color: "#000" }} />
              ) : (
                <Remove style={{ color: "#000" }} />
              )}

              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  paddingLeft: "4px",
                }}
              >
                {viewData.end === 3 ? "More Propeties" : "Less Propeties"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Hidden>
    </Box>
  );
}

import axios from "axios";
export const TakanValue = async (value) => {
  console.log("value---->", value);
  if (value != undefined) {
    return true;
  } else {
    return false;
  }
};
export const tokenValue = TakanValue();
console.log("tokenValue---->", tokenValue);

export const excerpt = (str, count) => {
  if (str.length > count) {
    str = str.substring(0, count) + " ... ";
  }
  return str;
};

export const getAPIdata = async ({ endPoint }) => {
  try {
    let res;
    res = await axios({
      url: endPoint,
      method: "GET",
    })
      .then((res) => {
        console.log("asdhasgdjgasd--->", res);
        if (res?.status === 200) {
          // return decryptData(res?.data);
        }
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      })
      .catch((error) => {
        console.log(error.response);
        return false;
        if (res?.data?.status === 400) {
          return false;
        }
        if (res?.data?.status === 401) {
          return false;
        }
        if (res?.data?.status === 205) {
          return false;
        }
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const putAPIdata = async ({ params, data, endPoint, headers }) => {
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "POST",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          return res?.data?.data;
        }
      })
      .catch((error) => {
        console.log("00000000--->", error?.response);

        return error?.response;
      });
    return res;
  } catch (error) {
    return error?.response;
  }
};

import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Apiconfigs from "../../ApiConfig/ApiConfig";
import { makeStyles } from "@mui/styles";
import axios from "axios";

import { useNavigate, useParams, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  btnSection: {
    borderRadius: "2px",
    border: "1px solid #FFD260 !important",
    cursor: "pointer",

    color: "#313131",
    textAlign: "center",
    "@media(max-width:767px)": {
      width: "105px !important",
    },
    "& button": {
      fontFamily: "'Poppins',serif !important",
      fontSize: "16px !important",
      fontStyle: "normal",
      fontWeight: "400 !important",
      lineHeight: "normal",
      textTransform: "capitalize",
      "@media(max-width:767px)": {
        height: "38px !important",
        fontSize: "15px !important",
        fontWeight: "600",
      },
      "&:hover": {
        position: "relative",
        color: "#000",
        "&::after": {
          content: "''",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "0",
          width: "85%",
          borderBottom: "none !important",
        },
      },
    },
  },
  nameButton: {
    fontFamily: "'Poppins',serif !important",
    fontSize: "16px !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    lineHeight: "normal",
    textTransform: "capitalize",
    borderRadius: "2px",
    border: "1px solid #FFD260 !important",
    cursor: "pointer",
    color: "#313131",
    textAlign: "center",
    margin: "3px !important",
    "&::after": {
      content: "''",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: "0",
      width: "85%",
      borderBottom: "none !important",
    },
  },
  contentBg: {
    background: "#FFD260",
    padding: "28px 31px",
    marginTop: "34px",
    "& h6": {
      color: "#000",
      textAlign: "center",
      fontFamily: "'Poppins',serif !important",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textTransform: "capitalize",
      paddingBottom: "7px",
    },
    "& button": {
      color: "#FFD260",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      letterSpacing: "2.4px",
      textTransform: "uppercase",
      padding: "15px 0px",
    },
  },
  descriptionDetails: {
    fontSize: "14px",
    fontFamily: "system-ui",
    fontWeight: "600",
    lineHeight: "25px",
    "& p": {
      fontFamily: "system-ui",
    },
  },
}));
export default function ExplorePropety({ type }) {
  const classes = useStyles();
  const location = useLocation();
  const [_propertyname, setPropertyName] = useState("");
  console.log("location----->", location);
  const searchData = new URLSearchParams(location.search).get("data");
  const blogList = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.listProperty,
        method: "GET",
        params: {
          search: location?.state,
        },
      });
      if (res) {
        setPropertyName(res?.data?.result?.docs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (location?.state) {
      blogList();
    }
  }, [location?.state]);
  const titleInURL = location?.state;
  const slug = titleInURL.replace(/\s+/g, "-").toLowerCase();

  useEffect(() => {
    window.history.pushState("/-", "/-", `/${slug}`);
  }, [titleInURL]);
  return (
    <Box>
      <Container>
        <Box mt={11} style={{ padding: "0px 14px" }}>
          <Box>
            {_propertyname &&
              _propertyname?.map((data, index) => {
                return (
                  <>
                    <Box m={"20px 0"}>
                      <Typography variant="h5">{data?.title}</Typography>
                    </Box>
                    <Box m={"20px 0"}>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        {data?.short_description}
                      </Typography>
                    </Box>
                    <Box>
                      <Box mt={2} maxWidth={500}>
                        <img src={data?.imageUrl} width={"100%"} />
                      </Box>
                    </Box>
                    <Box m={"20px 0"}>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        <div
                          className={classes.descriptionDetails}
                          dangerouslySetInnerHTML={{
                            __html: data?.description,
                          }}
                        ></div>
                      </Typography>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box textAlign="center">
        <img src="images/pagenotfound.png" width="100%" alt="page_not_found" />
        <Button large onClick={() => navigate("/")} variant="contained">
          Go Back To Home
        </Button>
      </Box>
    </div>
  );
}

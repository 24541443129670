import { Box, Button, Grid, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import Heading from "../../component/Heading";
import { makeStyles } from "@mui/styles";
import ApartMentCard from "../../component/ApartMentCard";
import {
  locationMandi,
  locationghitorni,
  loctionssChattarpur,
  locationGurgaon,
  locationSouthdelhi,
} from "../../data";
const list = [
  {
    location: "Mandi",
    tabview: "mandi",
    pathname: "/mandi",
  },
  {
    location: "Chattarpur",
    pathname: "/chattarpur",
    tabview: "chattarpur",
  },
  {
    location: "Ghitorni",
    pathname: "/ghitorni",
    tabview: "ghitorni",
  },
  {
    location: "South Delhi",
    pathname: "/southdelhi",
    tabview: "southdelhi",
  },
  {
    location: "Gurgaon",
    pathname: "/gurgaon",

    tabview: "gurgaon",
  },
];
const useStyles = makeStyles((theme) => ({
  btnSection: {
    borderRadius: "2px",
    border: "1px solid #FFD260 !important",
    cursor: "pointer",

    color: "#313131",
    textAlign: "center",
    "@media(max-width:767px)": {
      width: "105px !important",
    },
    "& button": {
      fontFamily: "'Poppins',serif !important",
      fontSize: "16px !important",
      fontStyle: "normal",
      fontWeight: "400 !important",
      lineHeight: "normal",
      textTransform: "capitalize",
      "@media(max-width:767px)": {
        height: "38px !important",
        fontSize: "15px !important",
        fontWeight: "600",
      },
      "&:hover": {
        position: "relative",
        color: "#000",
        "&::after": {
          content: "''",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "0",
          width: "85%",
          borderBottom: "none !important",
        },
      },
    },
  },
  nameButton: {
    fontFamily: "'Poppins',serif !important",
    fontSize: "16px !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    lineHeight: "normal",
    textTransform: "capitalize",
    borderRadius: "2px",
    border: "1px solid #FFD260 !important",
    cursor: "pointer",
    color: "#313131",
    textAlign: "center",
    margin: "3px !important",
    "&::after": {
      content: "''",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: "0",
      width: "85%",
      borderBottom: "none !important",
    },
  },
  contentBg: {
    background: "#FFD260",
    padding: "28px 31px",
    marginTop: "34px",
    "& h6": {
      color: "#000",
      textAlign: "center",
      fontFamily: "'Poppins',serif !important",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      textTransform: "capitalize",
      paddingBottom: "7px",
    },
    "& button": {
      color: "#FFD260",
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
      letterSpacing: "2.4px",
      textTransform: "uppercase",
      padding: "15px 15px",
    },
  },
  buttonClass: {
    "@media(max-width:767px)": {
      width: "100%",
    },
  },
}));
export default function ExplorePropety({ type }) {
  const classes = useStyles();
  const [tabView, setTabView] = useState("mandi");
  // const [tabView1, setTabView1] = useState("mandi");
  return (
    <Box mt={2}>
      <Container>
        {list.map((item) => {
          return (
            <Button
              sx={
                tabView === item.tabview
                  ? { background: "#FFD260 !important" }
                  : { background: "#FFF5DB !important" }
              }
              onClick={() => setTabView(item.name)}
              className={classes.nameButton}
            >
              {item.location}
            </Button>
          );
        })}
      </Container>

      {type != "ExplorePropety" && (
        <>
          <Container>
            <Box mb={4} mt={4}>
              <Heading title="Explore Our Properties" />
              <Box mt={2} mb={1.5}>
                <Heading title="By Locations" />
              </Box>

              <Box mt={3}>
                {/* <Container> */}
                {/* <Grid container spacing={2}> */}

                {/* </Grid> */}
                {/* </Container> */}
                <Box>
                  {list.map((item) => {
                    return (
                      <Button
                        sx={
                          tabView === item.tabview
                            ? { background: "#FFD260 !important" }
                            : { background: "#FFF5DB !important" }
                        }
                        onClick={() => setTabView(item.name)}
                        className={classes.nameButton}
                      >
                        {item.location}
                      </Button>
                    );
                  })}
                </Box>

                {type != "ExplorePropety" && (
                  <>
                    <Grid container spacing={2}>
                      {tabView === "mandi" && (
                        <>
                          {locationMandi.map((item, i) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box key={i} mt={3}>
                                <ApartMentCard item={item} i={i} />
                              </Box>
                            </Grid>
                          ))}
                        </>
                      )}
                      {tabView === "chattarpur" && (
                        <>
                          {loctionssChattarpur.map((item, i) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box mt={3} key={i}>
                                <ApartMentCard item={item} i={i} />
                              </Box>
                            </Grid>
                          ))}
                        </>
                      )}
                      {tabView === "gurgaon" && (
                        <>
                          {locationGurgaon.map((item, i) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box mt={3} key={i}>
                                <ApartMentCard item={item} i={i} />
                              </Box>
                            </Grid>
                          ))}
                        </>
                      )}
                      {tabView === "southdelhi" && (
                        <>
                          {locationSouthdelhi.map((item, i) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box mt={3} key={i}>
                                <ApartMentCard item={item} i={i} />
                              </Box>
                            </Grid>
                          ))}
                        </>
                      )}
                      {tabView === "ghitorni" && (
                        <>
                          {locationghitorni.map((item, i) => (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box mt={3} key={i}>
                                <ApartMentCard item={item} i={i} />
                              </Box>
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
          </Container>
          <Box className={classes.contentBg}>
            <Typography variant="h6">Are You Looking For</Typography>
            <Typography variant="h6">your Dream Home In Chattarpur?</Typography>
            <Box mt={2} width="100%" display={"flex"} justifyContent={"center"}>
              <Button
                className={classes.buttonClass}
                variant="contained"
                color="primary"
              >
                Schedule A Meeting
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { themeOptions } from "./typography";

const baseOptions = {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "transparent",
        },
        head: {
          background: "#F4F7FF",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { color: "#222" },
        colorSecondary: {
          "&.Mui-focused": {
            color: "#222",
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontSize: "22px",
          fontWeight: "600",
          lineHeight: "33px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // height: "48px",
          // borderRadius: "10px",
          border: "1px solid rgba(255, 255, 255, 0.40)",
          color: "#000",
        },
        notchedOutline: {
          borderColor: "rgba(0, 0, 0, 0.08)",
        },
        colorSecondary: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            color: "#222",
            borderColor: "#222",
          },
          "&.Mui-focused": {
            color: "#222",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          padding: "20px",
          width: "100%",
        },
        elevation1: {
          // background: `linear-gradient(0deg, rgba(69, 46, 84, 0.25), rgba(69, 46, 84, 0.25))`,
          borderRadius: "10px",
          padding: "15px",
          boxShadow: "none",
        },
        elevation2: {
          background:
            "linear-gradient(0deg, rgba(69, 46, 84, 0.15), rgba(69, 46, 84, 0.15))",
          border: "2px solid rgba(0, 0, 0, 0.15)",
          borderRadius: "10px",
          padding: "0px",
          boxShadow: "none",
          marginBottom: "30px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          alignItems: "self-start",
        },
        gutters: {
          paddingLeft: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(255, 255, 255, 0.40)",
          borderRadius: "10px !important",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgba(255, 255, 255, 0.40)",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "4px",
          fontSize: "12px",
          color: "#000",
          background: "red",
        },
        colorSecondary: {
          "&.Mui-checked": { color: "#FFD260" },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          paddingBottom: "0",
          color: "#FFD260",
        },
      },
    },
    MuiListItemSecondaryAction: {
      styleOverrides: {
        root: {
          right: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperScrollPaper: {
          Width: 450,
          maxWidth: "100%",
        },
        paper: {
          overflowY: "unset",
        },
        paperWidthSm: {
          maxWidth: "900px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 12,
          color: "#000",
          height: "0.1876em",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "18px",
          letterSpacing: "0px",
          textAlign: "left",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          color: "#FFD260",
          display: "flex",
          padding: "7px 15px",
          justifyContent: "center",
          alignItems: "center",
          textTransform: "capitalize",
          fontSize: "14px",
          fontWeight: "700",
          borderRadius: "5px",
          fontFamily: "'Space Grotesk', sans-serif",
          background:
            "linear-gradient(0deg, rgba(69, 46, 84, 0.25), rgba(69, 46, 84, 0.25))",
          whiteSpace: "pre",
          border: "1px solid #F53756",
          "&:hover": {
            color: "#fff",
            background: "linear-gradient(175deg, #FF9AAB 0%, #F53756 100%)",
            border: "1px solid transparent",
          },
        },
        containedPrimary: {
          color: "#FFD260",
          display: "flex",
          padding: "7px 15px",
          textTransform: "capitalize",
          justifyContent: "center",
          fontFamily: "'Space Grotesk', sans-serif",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: "700",
          borderRadius: "5px",
          border: "1px solid transparent",
          background: "#000",
          whiteSpace: "pre",
          "&:hover": {
            color: "#fff",
            background:
              "linear-gradient(0deg, rgba(69, 46, 84, 0.25), rgba(69, 46, 84, 0.25)) !important",
            border: "1px solid #FFD260",
          },
        },
        textPrimary: {
          color: "#000",
          fontSize: "14px",
          textTransform: "capitalize",
          fontWeight: "700",
          border: "none",
          "&:hover": {
            color: "#FFD260",
            background: "transparent !important",
            position: "relative",
            "&::after": {
              content: "''",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "0",
              width: "85%",
              borderBottom: "3px solid #FFD260",
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: "0",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: { border: "1px solid #FFD260" },
        select: {
          color: "#000 !important",
          backgroundColor: "transparent !important",
          fontSize: 15,
          textAlign: "left",
        },
        icon: {
          color: "#000 !important",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: { top: "47px" },
        list: {
          background: "#FFD260 !important",
          color: "#000 !important",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: { paddingLeft: "20px" },
      },
    },
    MuiModal: {
      styleOverrides: {
        backdrop: {
          background: "transparent !important",
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0px 65px !important",
          "@media (max-width: 780px)": {
            padding: "0px 16px !important",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none !important",
          cursor: "pointer",
        },
      },
    },
  },
};

export const createCustomTheme = (config = {}) => {
  let theme = createTheme({ ...baseOptions });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

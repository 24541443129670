import {
  Box,
  Container,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import { toast } from "react-hot-toast";
const useStyles = makeStyles((theme) => ({
  formContainer: {
    background: "#FFF5DB",
    strokeWidth: "1px",
    border: "1px solid #FFC83A",
    padding: "30px",
    borderRadius: "5px",
    marginBottom: "25px",
  },
}));
const styles = {
  borderRadius: "5px",
  background: "#FFC83A",
  // This will change the placeholder color for all TextField components
  "& ::placeholder": {
    color: "#535353 !important",

    fontFamily: "Poppins",
    fontSize: "12px !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    lineHeight: "normal",
    textTransform: "capitalize", // Change this to the desired color
  },
};
export default function ContactUs() {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);
  const [userData, setUserData] = useState({
    name: "",

    phone: "",
    email: "",

    message: "",
  });
  const postUserData = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };
  const submitData = async () => {
    const { name, phone, email, address, message } = userData;

    if (name && phone && email && message) {
      const res = fetch(
        "https://bn-developers-e3987-default-rtdb.firebaseio.com/userrecord.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,

            phone,
            email,

            message,
          }),
        }
      );

      if (res) {
        setUserData({
          name: "",

          phone: "",
          email: "",

          message: "",
        });
        toast.success("Your form has been submited");
      } else {
        toast.error("Please fill the all data");
      }
    } else {
      // toast.error("Please fill the all data");
      setIsSubmit(true);
    }
  };
  return (
    <Container>
      <Box
        width="100%"
        className={classes.formContainer}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box maxWidth={500} width={"100%"}>
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            do you have any query
          </Typography>
          <Typography
            style={{
              padding: "5px 0px 20px",
              color: "#000",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            Speak to Our Expert
          </Typography>
          <Box sx={{ marginBottom: "16px" }}>
            <TextField
              // sx={{ borderRadius: "2px", background: "#FFC83A", color: "#fff" }}
              sx={styles}
              fullWidth
              variant="outlined"
              placeholder="Name"
              name="name"
              value={userData.name}
              onChange={postUserData}
            />
            <FormHelperText error>
              {isSubmit && userData.name === "" && "Please enter your name"}
            </FormHelperText>
          </Box>
          <Box sx={{ marginBottom: "16px" }}>
            <TextField
              sx={styles}
              fullWidth
              variant="outlined"
              placeholder="Mobile Number"
              name="phone"
              value={userData.phone}
              onChange={postUserData}
            />
            <FormHelperText error>
              {isSubmit &&
                userData.phone === "" &&
                "Please enter your mobile number"}
            </FormHelperText>
          </Box>
          <Box sx={{ marginBottom: "16px" }}>
            <TextField
              sx={styles}
              fullWidth
              variant="outlined"
              placeholder="Email Id "
              value={userData.email}
              name="email"
              onChange={postUserData}
            />
            <FormHelperText error>
              {isSubmit &&
                userData.email === "" &&
                "Please enter your email id"}
            </FormHelperText>
          </Box>
          <Box sx={{ marginBottom: "16px" }}>
            <TextField
              sx={styles}
              multiline
              rows={3}
              fullWidth
              name="message"
              variant="outlined"
              placeholder="Enter Your Message"
              value={userData.message}
              onChange={postUserData}
            />
            <FormHelperText error>
              {isSubmit &&
                userData.message === "" &&
                "Please enter your message"}
            </FormHelperText>
          </Box>
          <CustomButton submitData={submitData} btnName="Submit" type="form" />
        </Box>
      </Box>
    </Container>
  );
}

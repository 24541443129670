import { Avatar, Box, Container, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import Heading from "../../component/Heading";
export default function AmentiesOffer() {
  const list = [
    {
      imgae: "images/apartment1.png",
      title: "Fire Safety",
    },
    {
      imgae: "images/apartment2.png",
      title: "Gated Compound",
    },
    {
      imgae: "images/apartment3.png",
      title: "Modular Kitchen",
    },
    {
      imgae: "images/apartment1.png",
      title: "Registry & Loan Availaible",
    },
    {
      imgae: "images/apartment2.png",
      title: "2 Source Of Water 24*7",
    },
    {
      imgae: "images/apartment3.png",
      title: "Branded Lift",
    },
    {
      imgae: "images/apartment1.png",
      title: "Registry & Loan Availaible",
    },
    {
      imgae: "images/apartment2.png",
      title: "2 Source Of Water 24*7",
    },
    {
      imgae: "images/apartment3.png",
      title: "Branded Lift",
    },
  ];
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#000",
          borderRadius: "100%",
        }}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#000",
          borderRadius: "100%",
        }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <Container>
      <Box mt={5} mb={3}>
        <Heading title="Amenities We Offer" />
      </Box>
      <Slider {...settings}>
        {list.map((item, i) => {
          return (
            <Box textAlign="center" className="displayCenter">
              <Avatar src={item.imgae} sx={{ width: "94px", height: "94px" }} />
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                {item.title}
              </Typography>
            </Box>
          );
        })}
      </Slider>
    </Container>
  );
}

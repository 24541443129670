import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Heading from "../../component/Heading";
import CustomButton from "./CustomButton";
import { db } from "./firebase";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import ReadMore from "../../component/ReadMore";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  CardBox: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    minHeight: "440px",
  },
}));
export default function BlogSection() {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    centerPadding: "60px",
    // autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const classes = useStyles();

  const history = useNavigate();
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    const blogRef = collection(db, "blogs");

    const firstFour = query(blogRef, orderBy("title"), limit(4));
    const docSnapshot = await getDocs(firstFour);
    setBlogs(docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    // setLastVisible(docSnapshot.docs[docSnapshot.docs.length - 1]);
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <Container>
      <Box mt={5} mb={3}>
        <Heading title="Our Blog" />
      </Box>
      <Slider {...settings}>
        {blogs &&
          blogs.map((item, i) => {
            return (
              <Box m={"10px"}>
                <Box
                  key={i}
                  sx={{ padding: "26px 10px 0px", margin: "10px" }}
                  className={classes.CardBox}
                >
                  <Box display="flex" alignItems="center" minHeight={"300px"}>
                    <img src={item?.imgUrl} width="100%" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        padding: "16px 0px 10px",
                        color: "#888",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      {/* {item.time} */}
                      {item?.timestamp.toDate().toDateString()}
                    </Typography>
                    <ReadMore text={item?.title} maxLength="60" />
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Slider>
      <Box sx={{ padding: "26px 0px 60px" }} className="displayCenterBox">
        <CustomButton
          btnName="Explore more Blogs"
          submitData={() => history("/blogs")}
        />
      </Box>
    </Container>
  );
}
